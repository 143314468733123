import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Services() {
    const navigate = useNavigate();
    const [scrollY, setScrollY] = useState(0);
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };
    const [lineLeft, setLineLeft] = useState("5px");

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        AOS.init();
    }, []);

    useEffect(() => {
        // mission
        if (scrollY == 0) {
            setLineLeft("40px")
        } else if (scrollY > 200) {
            setLineLeft("5px")
        } else {
            let left = scrollY * (-(0.175)) + 40;
            setLineLeft(left + "px");
        }
    }, [scrollY]);

    return (
        <div className="body">
            <div id="services-title">
                <h1 className="medium-large-text">Our Services Include: </h1>
                <img src="images/Dotted-Line.png" id="services-title-hr" className="title-hr" style={{ left: lineLeft }} />
            </div>
            <div className="section services-container">
                <div className="subsection-horiz services-subsection">
                    <div className="services">
                        <div className="service">
                            <p>Program Development</p>
                        </div>
                        <div className="service">
                            <p>Business Process Redesign</p>
                        </div>
                        <div className="service">
                            <p>Performance and Quality Management</p>
                        </div>
                        <div className="service">
                            <p>Stakeholder Engagement</p>
                        </div>
                        <div className="service past-project-link" onClick={(e) => navigate("../pastproject")}>
                            <p>View Our Past Projects...</p>
                        </div>
                    </div>
                </div>
                <div className="subsection-horiz" data-aos="fade-up" data-aos-once="true">
                    <div id="bullseye-container">
                        <img src="images/bullseye.jpg" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;