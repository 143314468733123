import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { FaPlus } from 'react-icons/fa';

function MainPage() {
    const [scrollY, setScrollY] = useState(0);
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };
    const [missionHrLeft, setMissionHrLeft] = useState("125px");
    const [abtusHrLeft, setAbtusMissionHrLeft] = useState("50px");
    const [abtusShapesRight, setAbtusShapesRight] = useState("0px");

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        // mission
        if (scrollY == 0) {
            setMissionHrLeft("75px")
        } else if (scrollY > 200) {
            setMissionHrLeft("25px")
        } else {
            let left = scrollY * (-(1 / 4)) + 75;
            setMissionHrLeft(left + "px");
        }

        // abt us
        if (scrollY <= 600) {
            setAbtusMissionHrLeft("50px")
        } else if (scrollY > 800) {
            setAbtusMissionHrLeft("0px")
        } else {
            let left = scrollY * (-(1 / 4)) + 200;
            setAbtusMissionHrLeft(left + "px");
        }

        // shapes
        if (scrollY <= 0) {
            setAbtusShapesRight("-200px")
        } else if (scrollY > 400) {
            setAbtusShapesRight("0px")
        } else {
            let right = scrollY * 0.5 - 200;
            setAbtusShapesRight(right + "px");
        }
        console.log(scrollY);
    }, [scrollY]);

    return (
        <div className="body">
            <div className="section">
                <div className="subsection-horiz">
                    <img id="circle-dashed" src="images/orange-burst.png" />
                    <h1 className="large-text">Our Mission</h1>
                    <img src="images/Dotted-Line.png" className="title-hr" id="mission-title-hr" style={{ left: missionHrLeft }}></img>
                </div>
                <div className="subsection-horiz">
                    <FaPlus id="mission-plus" color="#F99417" size={20}></FaPlus>
                    <BsPlusLg id="mission-plus2" color="#cecdcd" size={40}></BsPlusLg>
                    <div className="mission-text-container">
                        <p id="p-mission" className="paragraph">
                            Howard-Cooper Consulting (HCC) is a woman-owned business enterprise providing strategic consulting services to not-for-profit and government organizations.
                            <br /><br />
                            The HCC team has more than three decades of successful experience managing large-scale transformations in public benefits, workforce development, homeless rehousing, family and children's services and disaster recovery.
                            <br /><br />
                            We bring subject matter expertise, a deep understanding of agency operations and common-sense solutions to deliver results.
                        </p>
                        <div className="orange-link">
                            <a href="/services">Our Services <span className="arrow">→</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-us" className="section">
                <div className="subsection-horiz">
                    <div id="about-us-title" className="medium-large-text">
                        <h1>About Us</h1>
                        <img src="images/Dotted-Line.png" className="title-hr" id="about-us-title-hr" style={{ left: abtusHrLeft }}></img>
                    </div>
                    <img id="orange-line" src="images/orange-line.png"></img>
                </div>
                <div className="subsection-horiz">
                    <img id="abtus-shapes" src="images/bullseye.jpg" style={{ right: abtusShapesRight }}></img>
                    <div id="about-us-text">
                        <div className="medium-text">HCC is a Women-Owned Business Enterprise with New York City, New York State and federal Small Business Administration certification. </div>
                        <div id="orange-link" className="orange-link">
                            <a href="/pastproject">Explore Our Past Projects <span className="arrow">→</span></a>
                        </div>
                        <div id="mpsquare" className="square"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
