import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

function Header() {
    const navigate = useNavigate();
    const [crossVisibility, setCrossVisibility] = useState(false);
    function clicked() {
        setCrossVisibility(!crossVisibility);
    }
    return (
        <div id="header">
            <div id="header-container">
                <div className="logo-container" onClick={(e) => navigate("/")}>
                    <img className="logo" src="images/hcc.jpg"></img>
                </div>
                <nav id="navbar">
                    <ul id="nav-bar-elements">
                        <li><a href="/">ABOUT US</a></li>
                        <li><a href="/services">SERVICES</a></li>
                        <li><a href="/pastproject">PAST PROJECTS</a></li>
                        <li><a href="#footer">CONTACT US</a></li>
                    </ul>
                </nav>
                {!crossVisibility && <AiOutlineMenu id="burger" color="rgb(105,140,177)" size={30} onClick={() => clicked()}></AiOutlineMenu>}
                {crossVisibility && <RxCross1 id="cross" color="rgb(105,140,177)" size={30} onClick={() => clicked()} />}
            </div>
            <hr></hr>
            {crossVisibility && <nav id="mobile-navbar">
                <ul id="mobile-nav-bar-elements">
                    <li><a href="/">ABOUT US</a></li>
                    <li><a href="/services">SERVICES</a></li>
                    <li><a href="/pastproject">PAST PROJECTS</a></li>
                    <li><a href="#footer" onClick={(e) => setCrossVisibility(false)}>CONTACT US</a></li>
                </ul>
            </nav>}
        </div>
    );
}

export default Header;
