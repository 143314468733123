import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, [location]);
}

export default ScrollToTop;