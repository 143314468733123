function Footer() {
    return (
        <div id="footer">
            <div id="logo-container-footer">
                <img className="logo" src="images/hcc-transparent.png"></img>
            </div>
            <div id="footer-nav" className="subsection-horiz">
                <ul>
                    <li><a href="/">About Us</a></li>
                    <li><a href="/services">Sector Experience</a></li>
                    <li><a href="/pastprojects">Past Projects</a></li>
                    <li><a>Contact Us</a></li>
                </ul>
            </div>
            <div id="address" className="subsection-horiz">
                <h3>Contact</h3>
                <p>Tel: 917.750.9235</p>
                <p>Email: ellen@HCCstrategies.com</p>
                <div id="more-info-btn">
                    <a href="/company-info">More Info</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;