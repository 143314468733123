import { useEffect } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

function PastProjects() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className="body comp">
                <h1 className="medium-large-text past-projects-title">Our Past Projects</h1>
                <h2 className="past-projects-subtitle">Explore our portfolio showcasing a diverse array of successful past projects</h2>
                <div className="section pp-section">
                    <div className="subsection-horiz">
                        <img className="agriculture-img" src="images/agriculture-women.jpg"></img>
                    </div>
                    <div className="subsection-horiz" data-aos="fade-up" data-aos-once="true">
                        <p className="medium-text project-text">
                            Provided guidance to a global NGO on broadening its revenue streams to support women and their families through sustainable agricultural.
                        </p>
                        <div className="square1"></div>
                    </div>
                </div>
                <div className="section pp-section" data-aos="fade-up" data-aos-once="true">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Assisted a citywide vocational training organization in expanding career pathways for individuals in shelter.
                        </p>
                    </div>
                    <div className="subsection-horiz">
                        <div className="vocational-img-container">
                            <img className="vocational-img" src="images/vocational.jpg"></img>
                        </div>
                    </div>
                </div>
                <div className="section pp-section" data-aos="fade-up" data-aos-once="true">
                    <div className="subsection-horiz">
                        <img className="action-img" src="images/action.jpg" />
                    </div>
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Drafted a white paper on homeless trends and data-driven solutions for a leading university-affiliated homeless research and policy center.
                        </p>
                    </div>
                </div>
                <div className="section pp-section" data-aos="fade-up" data-aos-once="true">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Teamed up with a national training and consultation organization to support the redesign of a statewide homeless services system.
                        </p>
                    </div>
                    <div className="subsection-horiz">
                        <img className="collaboration-img" src="images/redesigning.jpg"></img>
                    </div>
                </div>
                <div className="section pp-section last-pp">
                    <div className="subsection-horiz">
                        <img className="improvement-img" src="images/TaxCredit.jpg"></img>
                    </div>
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Developed business improvements to expedite the processing of tenant applications for tax credit housing.
                        </p>
                    </div>
                </div>
            </div>
            <div className="body mobile">
                <h1 className="past-projects-title medium-large-text past-projects-title">Our Past Projects</h1>
                <h2 className="past-projects-subtitle">Explore our portfolio showcasing a diverse array of successful past projects</h2>
                <div className="section pp-section">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Provided guidance to a global NGO on broadening its revenue streams to support women and their families through sustainable agricultural.
                        </p>
                        <div className="square1"></div>
                    </div>
                    <div className="subsection-horiz" data-aos="fade-up" data-aos-once="true">
                        <img className="agriculture-img" src="images/agriculture-women.jpg"></img>
                    </div>
                </div>
                <div className="section pp-section" data-aos="fade-up" data-aos-once="true">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Assisted a citywide vocational training organization in expanding career pathways for individuals in shelter.
                        </p>
                    </div>
                    <div className="subsection-horiz">
                        <div className="vocational-img-container">
                            <img className="vocational-img" src="images/vocational.jpg"></img>
                        </div>
                    </div>
                </div>
                <div className="section pp-section" data-aos="fade-up" data-aos-once="true">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Drafted a white paper on homeless trends and data-driven solutions for a leading university-affiliated homeless research and policy center.
                        </p>
                    </div>
                    <div className="subsection-horiz">
                        <img className="action-img" src="images/action.jpg" />
                    </div>
                </div>
                <div className="section pp-section" data-aos="fade-up" data-aos-once="true">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Teamed up with a national training and consultation organization to support the redesign of a statewide homeless services system.
                        </p>
                    </div>
                    <div className="subsection-horiz">
                        <img className="collaboration-img" src="images/redesigning.jpg"></img>
                    </div>
                </div>
                <div className="section pp-section last-pp">
                    <div className="subsection-horiz">
                        <p className="medium-text project-text">
                            Developed business improvements to expedite the processing of tenant applications for tax credit housing.
                        </p>
                    </div>
                    <div className="subsection-horiz">
                        <img className="improvement-img" src="images/TaxCredit.jpg"></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PastProjects;