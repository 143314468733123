import { Route, Routes } from "react-router-dom";
import MainPage from "./MainPage";
import Header from "./Header";
import Services from "./Services";
import Footer from "./Footer";
import PastProjects from "./PastProjects";
import CompanyInfo from "./CompanyInfo";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div className="app">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pastproject" element={<PastProjects />} />
        <Route path="/company-info" element={<CompanyInfo />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
