import './css/company-info.css';

function CompanyInfo() {
    return (
        <div className="body">
            <div className="container1">
                <div className="container2">
                    <div className="medium-text item"><b>Registered Company Name: </b>Howard-Cooper Consulting</div>
                    <div className="medium-text item"><b>Employer Identification Number: </b>82-2048424</div>
                    <div className="medium-text item"><b>D-U-N-S Number: </b>116722824</div>
                    <div className="medium-text item"><b>CAGE Code: </b>86WF7</div>
                    <div className="medium-text item"><b>NAICS Code: </b>54161 - Management Consulting Services 541611 - Administrative Management and General Management Consulting Service </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyInfo;